import gql from 'graphql-tag';

const ConversationMutation = gql`
  mutation MyMutation($eventId: String!, $name: String!, $partner: String!) {
    createConversation(eventId: $eventId, name: $name, partner: $partner) {
      createdAt
      eventId
      id
      name
      partner
    }
  }
`;

export default ConversationMutation;
